import React from "react";
import "./App.css";
import SwiperSlide from "./components/swiperSlide/SwiperSlide";
import Gallery from "./components/gallery/Gallery";
import Event from "./components/event/Event";
import Testimonial from "./components/testimonial/Testimonial";
import Member from "./components/member/Member";

function App() {
  return (
    <>
      <header id="header" className="header sticky-top">
        <div className="d-flex align-items-center">
          <div className="container position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center me-auto">
              <img
                width="250px"
                src="static/img/logo.png"
                alt="Ayan Maternity"
              />
            </a>

            <nav id="navmenu" className="navmenu">
              <ul>
                <li>
                  <a href="#" className="active">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#events">Events</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#members">Members</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </nav>
            <a
              className="cta-btn"
              href="https://www.paypal.com/donate/?hosted_button_id=YWLHQM8TSZ59G"
            >
              Donate Now
            </a>
          </div>
        </div>
      </header>

      <main className="main">
        <section id="hero" className="hero section light-background">
          <img className="banner" src="assets/img/mom-baby3.jpeg" />
          <div className="position-relative mx-5">
            <div className="content row gy-4">
              <div className="col-md-3 col-sm-4 d-flex align-items-stretch">
                <div
                  className="why-box"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <h3>Our Mission</h3>
                  <p>
                    Ayan Maternity Health Care Support provides premier
                    perinatal and maternal care. Our commitment lies in helping
                    pregnant women and new mothers who confront social and
                    economic challenges, or those experiencing hardships. We
                    cater to women with disabilities, new African immigrants,
                    refugees, and low-income families, ensuring crucial care for
                    those with limited access to the conventional healthcare
                    system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="vision" className="vision container section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Vision</h2>
          </div>
          <div className="content row" style={{ padding: "0 1rem" }}>
            <div className="col-lg-12 d-flex align-items-stretch">
              <div className="d-flex flex-column justify-content-center">
                <div className="row gy-4">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    >
                      <i className="bi bi-clipboard-data"></i>
                      <h4>Free Service</h4>
                      <p>
                        Ayan Maternity provides perinatal and maternal services
                        in King County by providing birth doulas, postpartum
                        doulas, breastfeeding/lactation support, connection to
                        other social services, and childbirth education and
                        parenting classes, Daddy workshops and Community
                        Maternal Education Class. The services are essential for
                        better birth outcomes, to reduce maternal and infant
                        health inequities, and for better treatment of women,
                        particularly women of color.
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                      data-aos="zoom-out"
                      data-aos-delay="400"
                    >
                      <i className="bi bi-gem"></i>
                      <h4>Doulas Support</h4>
                      <p>
                        Pregnant and birthing mothers, and most especially women
                        of color, can be systematically demoralized, patronized,
                        and unheard by the health care system and often denied
                        the dignity that they deserve. Doulas offer a solution
                        by advocating for the mother in the labor and delivery
                        process which can save more women of color from
                        experiencing complications or dying in childbirth or
                        post-delivery.
                      </p>
                      <div className="event-info">
                        <div className="btn-wrapper">
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScpmXswFud8LXZvwOjUDtwBJYpTXQGa0_EWBJOvaODzHS9f9g/viewform"
                            target="_blank"
                            className="reg-btn"
                          >
                            <span>Register Now</span>
                            <i className="bi bi-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                      data-aos="zoom-out"
                      data-aos-delay="500"
                    >
                      <i className="bi bi-inboxes"></i>
                      <h4>Improved birth outcomes</h4>
                      <p>
                        Mothers matched with a doula also have better birth
                        outcomes. Doula-assisted mothers were four times less
                        likely to have a low birth weight (LBW) baby, two times
                        less likely to experience a birth complication involving
                        themselves or their baby, and significantly more likely
                        to initiate breastfeeding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="events" className="gallery section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Events</h2>
          </div>
          <Event />
          <div
            className="container-fluid"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="row g-0">
              <Gallery />
            </div>
          </div>
        </section>

        <section id="services" className="services section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p></p>
          </div>

          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fas fa-heartbeat"></i>
                  </div>
                  <a href="#" className="stretched-link">
                    <h3>Birth Doula Workshop Training</h3>
                  </a>
                  <p>
                    We offer training workshops for birth doulas, aiming to
                    increase the number of trained professionals who can support
                    expecting mothers during childbirth.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fas fa-pills"></i>
                  </div>
                  <a href="#" className="stretched-link">
                    <h3>Improving Birth Outcomes</h3>
                  </a>
                  <p>
                    The organization strives to advance health equity,
                    particularly in maternal and child health. Their programs
                    are geared towards improving birth outcomes and reducing
                    health disparities among the communities they serve.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fas fa-hospital-user"></i>
                  </div>
                  <a href="#" className="stretched-link">
                    <h3>Continue Maternal Education</h3>
                  </a>
                  <p>
                    We organize events and provide ongoing education for doulas
                    to ensure they are equipped with the latest knowledge and
                    skills. These classes are designed to educate mothers and
                    families about maternal health, childbirth, and postpartum
                    care.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 position-relative align-self-start"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="about-video_container">
                  <div className="about-video">
                    <img
                      src="static/img/birth-equity.png"
                      className="about-video-img img-fluid"
                      alt="about us"
                    />
                  </div>
                  <div className="about-video_content">
                    <h4>Birth Equity</h4>
                    <p></p>
                    <div className="watch-video">
                      <a
                        href="https://drive.google.com/file/d/1CDLN5eQhpGcjSZjKmFl1gNPU5a7T7Rv0/preview"
                        className="glightbox pulsating-play-btn"
                      ></a>
                      <span>watch now</span>
                    </div>
                  </div>
                </div>
                <div className="about-video_container">
                  <div className="about-video">
                    <img
                      src="static/img/health-equity.png"
                      className="about-video-img img-fluid"
                      alt="about us"
                    />
                  </div>
                  <div className="about-video_content">
                    <h4>Health Equity</h4>
                    <p></p>
                    <div className="watch-video">
                      <a
                        href="https://drive.google.com/file/d/1rYUYyTSBbYYKzqqg68Ygbrb6-56aZHAI/preview"
                        className="glightbox pulsating-play-btn"
                      ></a>
                      <span>watch now</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3>About Us</h3>
                <p>
                  Ayan Maternity Healthcare Support is a nonprofit organization
                  based in King County, Washington, that provides comprehensive
                  prenatal and postnatal support to pregnant individuals and
                  families who are at risk of experiencing health
                  disparities.The organization primarily serves African,
                  Immigrant, Refugee, and low-income families.
                </p>
                <ul>
                  <li>
                    <i className="fa-solid fa-person-chalkboard"></i>
                    <div>
                      <h5>Birth Doula Workshop Training</h5>
                      <p>
                        We offer training workshops for birth doulas, aiming to
                        increase the number of trained professionals who can
                        support expecting mothers during childbirth.
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="fa-solid fa-school-circle-check"></i>
                    <div>
                      <h5>Events and Continuing Education for Doulas</h5>
                      <p>
                        We organize events and provide ongoing education for
                        doulas to ensure they are equipped with the latest
                        knowledge and skills. These classes are designed to
                        educate mothers and families about maternal health,
                        childbirth, and postpartum care.
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="fa-solid fa-hands-holding-child"></i>
                    <div>
                      <h5>Culturally Competent Care</h5>
                      <p>
                        Our services are culturally sensitive and aim to provide
                        compassionate and accessible care to families in King
                        County.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Users of Organization</h2>
          </div>
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <Testimonial />
          </div>
        </section>
        <section id="members" className="members section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Team</h2>
          </div>
          <Member />
        </section>

        <section id="clients" className="clients section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Partners</h2>
          </div>
          <div className="container">
            <SwiperSlide />
          </div>
        </section>

        <section id="contact" className="contact section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Contact</h2>
            <p></p>
          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="offset-2 col-lg-8">
                <form
                  action="forms/contact.php"
                  method="post"
                  className="php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>

                    <div className="col-md-6 ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>

                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </div>

                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>

                      <button type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer" className="footer light-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span className="sitename">
                  Ayan Maternity Health Care Support
                </span>
              </a>
              <div className="footer-contact pt-3">
                <p>14205 SE 36th Street, Suite 100,</p>
                <p>Bellevue WA 98006</p>
                <p className="mt-3">
                  <strong>Fax:</strong> <span>425-649-1199</span>
                </p>
                <p>
                  <strong>Direct:</strong> <span>+1 425-305-7377</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>aabdu@ayanmaternitylove.org</span>
                </p>
              </div>
              <div className="social-links d-flex mt-4">
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            <div
              className="col-lg-8 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2691.451866016684!2d-122.15189452377138!3d47.57845237118726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ea67fcbb6df%3A0x31b52d010ce8e5da!2s14205%20SE%2036th%20St%20Suite%20100%2C%20Bellevue%2C%20WA%2098006%2C%20USA!5e0!3m2!1sen!2sin!4v1721928154517!5m2!1sen!2sin"
                width="100%"
                height="270px"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="text-center mt-4"></div>
      </footer>
    </>
  );
}

export default App;
